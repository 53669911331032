import {
  lazy,
  LazyExoticComponent,
  Suspense,
  useEffect,
  useState,
} from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  isFeatureEnabled,
  FeatureToggleIdentifier,
} from "../services/feature-toggle-adapter";
import RedirectIfNotAuthenticated from "../components/RedirectIfNotAuthenticated";
import RedirectIfAuthenticated from "../components/RedirectIfAuthenticated";
import ScrollToTop from "./ScrollToTop";
import AuthPage from "../pages/AuthPage";
import QuestionnairePrompt from "../pages/QuestionnairePromptPage";
import HandleExpiredSession from "../components/HandleExpiredSession";
import MemberRequiredActionsPage from "../pages/MemberRequiredActionsPage";
import MedicalAssistancePage from "../pages/MedicalAssistancePage";
import HealthMeasurementPage from "../pages/HealthMeasurementPage";
import MembershipDetailPage from "../pages/MembershipDetailPage";
import MembershipManagementPage from "../pages/MembershipManagementPage";
import MembershipContactDetailsPage from "../pages/MembershipContactDetailsPage";
import MembershipTermsPage from "../pages/MembershipTermsPage";
import MaxWidthContainer from "../components/MaxWidthContainer";
import DashboardPage from "../pages/DashboardPage";
import MorePage from "../pages/MorePage";
import CommunicationPreferencesPage from "../pages/CommunicationPreferencesPage";
import HealthMeasurementDetailPage from "../pages/HealthMeasurementDetailPage";
import MyBenefitsPage from "../pages/MyBenefitsPage";
import MyHealthPage from "../pages/MyHealthPage";
import WelcomePage from "../pages/WelcomePage";
import MarketplacePage from "../pages/MarketplacePage";
import MarketplaceProductDetailPage from "../pages/MarketplaceProductDetailPage";
import MarketplaceProductComparisonPage from "../pages/MarketplaceProductComparisonPage";
import AppVersionChangeController from "../components/AppVersionChangeController";
import BinahScanFlow from "../components/BinahScanFlow";
import { trackPageView } from "../services/analytics-adapter";
import { maxWidthConfig } from "../theme";
import SuspenseFallback from "../components/SuspenseFallback";
import AboutAppPage from "../pages/AboutAppPage";
import OpenSourceLicensesPage from "../pages/OpenSourceLicensesPage";
import MedicalAssistanceUnsupportedPage from "../pages/MedicalAssistanceUnsupportedPage";
import RedirectHandler from "../components/RedirectHandler";
import MarketplaceSelfServiceConfirmation from "../components/MarketplaceSelfServiceConfirmation";
import PublicBinahScanFlow from "../components/PublicBinahScanFlow";
import GetSupport from "../components/GetSupport";
import GetHelpModal from "../components/GetHelpModal";
import MembershipPolicyDocumentsPage from "../pages/MembershipPolicyDocumentsPage";
import InterCareMedicalAssistanceFlow from "../components/IntercareMedicalAssistance";
import PaymentFailure from "../components/PaymentFailure";
import MembershipAddDependentsFlow from "../components/MembershipAddDependents";
import CollectUserQueryFlow from "../components/CollectUserQuery";
import ChatBotPage from "../pages/ChatBotPage";
import MessagesPage from "../pages/MessagesPage";
import MessageDetailsPage from "../pages/MessageDetailsPage";
import RedirectIfThirdPartyMember from "@/components/RedirectIfThirdPartyMember";
import ThirdPartyMedicalAssistanceFlow from "@/components/ThirdPartyMedicalAssistanceFlow";

interface PlaygroundPageProps {
  changeMaxWidth: (arg: string) => void;
}

let PlaygroundPage: LazyExoticComponent<React.FC<PlaygroundPageProps>> | null =
  null;
if (isFeatureEnabled(FeatureToggleIdentifier.SHOW_PLAYGROUND)) {
  PlaygroundPage = lazy(() => import("../pages/PlaygroundPage"));
}

let previousLocation = "";

export default function Router() {
  const location = useLocation();
  const navigate = useNavigate();
  const locationState = location.state as {
    from: string;
    isRedirected: boolean;
  };
  const from = locationState && locationState.from;
  const [maxWidth, setMaxWidth] = useState(maxWidthConfig.defaultMaxWidth);

  useEffect(() => {
    trackPageView({
      "page.url": location.pathname,
      "page.sourceUrl": previousLocation,
      other: { ...location.state },
    });
    previousLocation = location.pathname + location.search;
  }, [location]);

  function changeMaxWidth(maxWidth: string) {
    setMaxWidth(maxWidth);
  }

  return (
    <ScrollToTop>
      <MaxWidthContainer maxWidth={maxWidth}>
        <RedirectHandler>
          <Routes location={from || location}>
            <Route
              index
              element={
                <RedirectIfAuthenticated>
                  <WelcomePage />
                </RedirectIfAuthenticated>
              }
            />
            <Route
              path="/login"
              element={
                <RedirectIfAuthenticated>
                  <AuthPage />
                </RedirectIfAuthenticated>
              }
            />
            <Route
              path="/member-required-actions"
              element={<MemberRequiredActionsPage />}
            />

            <Route element={<RedirectIfNotAuthenticated />}>
              <Route
                path="/questionnaire-prompt"
                element={<QuestionnairePrompt />}
              />
              <Route path="/home" element={<DashboardPage />} />
              <Route
                path="/home/marketplace/:productIDs?"
                element={<MarketplacePage />}
              />
              <Route
                path="/home/marketplace/product/:productID"
                element={<MarketplaceProductDetailPage />}
              />
              <Route
                path="/home/marketplace/product/:productID/confirm-self-service-sign-up"
                element={<MarketplaceSelfServiceConfirmation />}
              />
              <Route
                path="/home/marketplace/product-comparison/:productIDs"
                element={<MarketplaceProductComparisonPage />}
              />
              <Route
                path="/home/my-health/health-profile"
                element={<MyHealthPage />}
              />
              <Route
                path="/home/my-health/health-record"
                element={<MyHealthPage />}
              />
              <Route
                path="/home/my-health/contact-details"
                element={<MyHealthPage />}
              />
              <Route
                path="/home/my-health/communication-preferences"
                element={<CommunicationPreferencesPage />}
              />

              <Route path="/home/help/about-app" element={<AboutAppPage />} />

              <Route path="/home/more/get-support" element={<GetSupport />} />

              <Route
                path="/home/help/about-app/open-source-licenses"
                element={<OpenSourceLicensesPage />}
              />

              <Route
                path="/home/my-benefits/benefits"
                element={<MyBenefitsPage />}
              />
              <Route
                path="/home/my-benefits/membership"
                element={
                  <RedirectIfThirdPartyMember>
                    <MyBenefitsPage />
                  </RedirectIfThirdPartyMember>
                }
              />
              <Route
                path="/home/get-medical-assistance"
                element={<MedicalAssistancePage />}
              />
              {isFeatureEnabled(
                FeatureToggleIdentifier.ENABLE_INTERCARE_MEDICAL_ASSISTANCE
              ) && (
                <>
                  <Route
                    path="/home/get-medical-assistance/intercare"
                    element={<InterCareMedicalAssistanceFlow />}
                  />

                  <Route
                    path="/home/get-medical-assistance/intercare/payment-failure"
                    element={<PaymentFailure />}
                  />
                </>
              )}
              {isFeatureEnabled(
                FeatureToggleIdentifier.ENABLE_THIRD_PARTY_AUTHENTICATION
              ) && (
                <>
                  <Route
                    path="/home/get-medical-assistance/third-party"
                    element={<ThirdPartyMedicalAssistanceFlow />}
                  />
                </>
              )}
              {isFeatureEnabled(
                FeatureToggleIdentifier.ENABLE_INTERCARE_MEDICAL_ASSISTANCE
              ) && (
                <Route
                  path="/home/get-medical-assistance/intercare/:id"
                  element={<InterCareMedicalAssistanceFlow />}
                />
              )}

              {isFeatureEnabled(FeatureToggleIdentifier.ENABLE_CHAT_BOT) && (
                <Route
                  path="/home/sweet-life/chat/:chatBotType"
                  element={<ChatBotPage />}
                />
              )}

              <Route
                path="home/get-medical-assistance/unsupported"
                element={<MedicalAssistanceUnsupportedPage />}
              />
              <Route path="/home/more" element={<MorePage />} />
              <Route
                path="/home/health-measurement/:healthMeasurementIdentifier"
                element={<HealthMeasurementPage />}
              />
              <Route
                path="/home/health-measurement/:healthMeasurementIdentifier/detail"
                element={<HealthMeasurementDetailPage />}
              />
              <Route
                path="/home/memberships/:membershipIdentifier"
                element={
                  <RedirectIfThirdPartyMember>
                    <MembershipDetailPage />
                  </RedirectIfThirdPartyMember>
                }
              />
              <Route
                path="/home/memberships/:membershipIdentifier/contact"
                element={
                  <RedirectIfThirdPartyMember>
                    <MembershipContactDetailsPage />
                  </RedirectIfThirdPartyMember>
                }
              />
              <Route
                path="/home/memberships/:membershipIdentifier/manage"
                element={
                  <RedirectIfThirdPartyMember>
                    <MembershipManagementPage />
                  </RedirectIfThirdPartyMember>
                }
              />
              <Route
                path="/home/memberships/:membershipIdentifier/terms"
                element={
                  <RedirectIfThirdPartyMember>
                    <MembershipTermsPage />
                  </RedirectIfThirdPartyMember>
                }
              />
              <Route
                path="/home/memberships/:membershipIdentifier/policy-documents"
                element={
                  <RedirectIfThirdPartyMember>
                    <MembershipPolicyDocumentsPage />
                  </RedirectIfThirdPartyMember>
                }
              />
              <Route
                path="/home/memberships/:membershipIdentifier/add-people"
                element={
                  <RedirectIfThirdPartyMember>
                    <MembershipAddDependentsFlow />
                  </RedirectIfThirdPartyMember>
                }
              />
              <Route
                path="/binah-scan"
                element={
                  <BinahScanFlow onCompletedFlow={() => navigate("/home")} />
                }
              />
              {isFeatureEnabled(
                FeatureToggleIdentifier.ENABLE_IN_APP_MESSAGING
              ) && (
                <>
                  <Route path="/home/messages" element={<MessagesPage />} />
                  <Route
                    path="/home/messages/:messageId"
                    element={<MessageDetailsPage />}
                  />
                </>
              )}
            </Route>

            <Route
              path="/auth-session-error"
              element={<HandleExpiredSession />}
            />
            <Route
              path="/playground/*"
              element={
                <Suspense fallback={<SuspenseFallback />}>
                  {PlaygroundPage ? (
                    <PlaygroundPage changeMaxWidth={changeMaxWidth} />
                  ) : (
                    <Navigate to="/" replace />
                  )}
                </Suspense>
              }
            />
            <Route
              path="*"
              element={<Navigate to="/" state={{ isRedirected: true }} />}
            />
            <Route
              path="/public-binah-scan"
              element={
                <Suspense fallback={<SuspenseFallback />}>
                  <PublicBinahScanFlow
                    onCompletedFlow={() => navigate("/public-binah-scan")}
                  />
                </Suspense>
              }
            />
            {isFeatureEnabled(
              FeatureToggleIdentifier.ENABLE_GET_HELP_LOG_QUERY_FORM
            ) && (
              <Route
                path="/get-help/log-query"
                element={<CollectUserQueryFlow />}
              />
            )}
          </Routes>
          <Routes>
            <Route path="/get-help" element={<GetHelpModal />} />
          </Routes>

          {from && (
            <Routes>
              <Route
                path="/home/get-medical-assistance/prompt"
                element={<MedicalAssistancePage showInformationPrompt />}
              />
            </Routes>
          )}

          {isFeatureEnabled(FeatureToggleIdentifier.ENABLE_APP_AUTO_RELOAD) && (
            <AppVersionChangeController />
          )}
        </RedirectHandler>
      </MaxWidthContainer>
    </ScrollToTop>
  );
}
