import { useMachine } from "@xstate/react";
import { eventNames, ThirdPartyMedicalAssistanceFlowMachine } from "./machine";
import { ThirdPartyConsultationTypeSelection } from "../ThirdPartyConsultationTypeSelection";
import { ThirdPartyServices } from "@/services/core-api-adapter";
import { ThirdPartyMedicalAssistanceServicePrompt } from "../ThirdPartyMedicalAssistanceServicePrompt";

export default function ThirdPartyMedicalAssistanceFlow() {
  const [machineState, machineDispatch] = useMachine(
    ThirdPartyMedicalAssistanceFlowMachine
  );

  function onBackButtonClick() {
    machineDispatch({
      type: eventNames.BACK_BUTTON_CLICKED,
    });
  }

  function onContinueButtonClick() {
    machineDispatch({
      type: eventNames.CONTINUE_BUTTON_CLICKED,
    });
  }

  function onNurseConsultClick() {
    machineDispatch({
      type: eventNames.CONTINUE_BUTTON_CLICKED,
      data: ThirdPartyServices.NURSE_CONSULT,
    });
  }

  return (
    <>
      {machineState.matches("selectingConsultationType") && (
        <ThirdPartyConsultationTypeSelection
          onNurseConsultClick={onNurseConsultClick}
          onDoctorConsultClick={() => {}}
          onLaboratoryServicesClick={() => {}}
          onPharmacyServicesClick={() => {}}
          onClinicAppointmentClick={() => {}}
        />
      )}

      {machineState.matches("showingPrompt") &&
        machineState.context.selectedConsultationType && (
          <ThirdPartyMedicalAssistanceServicePrompt
            onContinueButtonClick={onContinueButtonClick}
            onBackButtonClick={onBackButtonClick}
            serviceType={machineState.context.selectedConsultationType}
          />
        )}
    </>
  );
}
