import { ThirdPartyServices } from "@/services/core-api-adapter";
import { setup, assign } from "xstate";

interface Context {
  selectedConsultationType: ThirdPartyServices | null;
}

export const eventNames = {
  CONTINUE_BUTTON_CLICKED: "CONTINUE_BUTTON_CLICKED",
  BACK_BUTTON_CLICKED: "BACK_BUTTON_CLICKED",
};

const initialContextValues: Context = {
  selectedConsultationType: null,
};

export const ThirdPartyMedicalAssistanceFlowMachine = setup({
  types: {
    context: {} as Context,
    events: {} as any,
  },
  actions: {
    addSelectedConsultationTypeToContext: assign(({ event }) => {
      return { selectedConsultationType: event.data };
    }),
  },
}).createMachine({
  context: initialContextValues,
  id: "ThirdPartyMedicalAssistanceFlow",
  initial: "selectingConsultationType",
  states: {
    selectingConsultationType: {
      on: {
        [eventNames.CONTINUE_BUTTON_CLICKED]: {
          target: "showingPrompt",
          actions: { type: "addSelectedConsultationTypeToContext" },
        },
      },
      entry: assign(initialContextValues),
    },
    showingPrompt: {
      on: {
        BACK_BUTTON_CLICKED: {
          target: "selectingConsultationType",
        },
      },
    },
  },
});
